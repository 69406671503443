<template>
   <div class="col-12 p-0 produto-linha" v-if="view == 'Linha'">
      <div class="card mb-1" :class="index % 2 == 0 ? '' : 'invert'">
         <div class="card-body p-3">
            <div class="row m-0 font-12 w-100">
               <div class="col-12 col-md-9 px-1 align-self-center mb-2 mb-md-0 cursor-pointer" title="Visualizar" @click="visualizar">
                  <div class="row m-0">
                     <div class="col-12 p-0 text-center text-md-start mb-2">
                        <strong class="limitador font-16" :title="pedido.venda.status" :class="String(pedido.venda.status).includes('Pendente') ? 'color-pendente' : String(pedido.venda.status).includes('Em separação') ? 'color-separacao' : String(pedido.venda.status).includes('Cancelado') ? 'color-cancelado' : String(pedido.venda.status).includes('Entrega em andamento') ? 'color-entrega' : String(pedido.venda.status).includes('Finalizado') ? 'color-finalizado' : ''">
                           {{ pedido.venda.status }}
                        </strong>
                     </div>
                     <div class="col-5 p-0 text-center text-md-start mb-2 mb-md-0">
                        <div class="limitador">
                           <i class="far fa-calendar-check color-theme font-10 me-1"></i><strong class="mx-1 d-none d-xl-inline text-secondary">Compra:</strong> 
                           {{ pedido.venda.dataCompra.dayOfMonth +'/'+ pedido.venda.dataCompra.monthValue +'/'+ pedido.venda.dataCompra.year +' '+ pedido.venda.dataCompra.hour +':'+ pedido.venda.dataCompra.minute }}
                        </div>
                     </div>
                     <div class="col-7 p-0 text-center text-md-start mb-2 mb-md-0">
                        <div class="limitador text-capitalize">
                           <i class="far fa-calendar-exclamation color-theme font-10 me-1"></i><strong class="mx-1 d-none d-xl-inline text-secondary">Última movimentação:</strong> 
                           {{ pedido.venda.dataUltMov.dayOfMonth +'/'+ pedido.venda.dataUltMov.monthValue +'/'+ pedido.venda.dataUltMov.year +' '+ pedido.venda.dataUltMov.hour +':'+ pedido.venda.dataUltMov.minute }}
                        </div>
                     </div>
                     <div class="col-12 p-0 text-center text-md-start d-none d-md-block">
                        <div class="limitador text-capitalize">
                           <i class="far fa-user color-theme font-10 me-1"></i><strong class="mx-1 d-none d-xl-inline text-secondary">Cliente:</strong> 
                           {{ pedido.venda.nomeUsuario == null ? 'Desconhecido' : pedido.venda.nomeUsuario.toLowerCase() }}
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-6 col-md-2 px-1 align-self-center text-center font-14 cursor-pointer" title="Visualizar" @click="visualizar" style="line-height: 15px;">
                  <strong class="font-17 color-theme">Valor</strong>
                  <br><small class="font-10 color-theme mt-2 d-inline-block">R$ </small>
                  {{ pedido.venda.valorTotal == null ? 0.00 : (parseFloat(pedido.venda.valorTotal) + parseFloat(pedido.venda.frete == null || isNaN(pedido.venda.frete) ? 0 : pedido.venda.frete)).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  <small class="d-none d-md-inline text-secondary"><br>{{ pedido.venda.formaPgto }}</small>
                  <small class="d-inline d-md-none text-secondary"> ({{ pedido.venda.formaPgto }})</small>
               </div>
               <div class="col-6 col-md-1 d-flex p-0 align-self-center text-center font-16">
                  <span class="w-100 btn-icone"><i class="fal fa-pen" @click="editarStatus"></i><small>Status</small></span>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="card card-produto hover px-12" v-else>
      <div class="text-center cursor-pointer" @click="visualizar">
         <img src="@/assets/admin/images/iconePedidosPendente.png" alt="Icone" title="Visualizar" class="mt-0" v-if="String(pedido.venda.status).includes('Pendente')">
         <img src="@/assets/admin/images/iconePedidosSeparacao.png" alt="Icone" title="Visualizar" class="mt-0" v-else-if="String(pedido.venda.status).includes('Em separação')">
         <img src="@/assets/admin/images/iconePedidosCancelado.png" alt="Icone" title="Visualizar" class="mt-0" v-else-if="String(pedido.venda.status).includes('Cancelado')">
         <img src="@/assets/admin/images/iconePedidosEntrega.png" alt="Icone" title="Visualizar" class="mt-0" v-else-if="String(pedido.venda.status).includes('Entrega em andamento')">
         <img src="@/assets/admin/images/iconePedidosFinalizado.png" alt="Icone" title="Visualizar" class="mt-0" v-else-if="String(pedido.venda.status).includes('Finalizado')">
         <img src="@/assets/admin/images/icons_home/iconePedidos.png" alt="Icone" title="Visualizar" class="mt-0" v-else>
         <div class="limitador font-16 my-1" :class="String(pedido.venda.status).includes('Pendente') ? 'color-pendente' : String(pedido.venda.status).includes('Em separação') ? 'color-separacao' : String(pedido.venda.status).includes('Cancelado') ? 'color-cancelado' : String(pedido.venda.status).includes('Entrega em andamento') ? 'color-entrega' : String(pedido.venda.status).includes('Finalizado') ? 'color-finalizado' : ''">
            <strong class="align-self-center mx-auto">{{ pedido.venda.status }}</strong>
         </div>
         <div class="limitador font-15 mb-2" title="Valor (Forma de pagamento)">
            <small class="font-12">R$ </small>
            {{ pedido.venda.valorTotal == null ? 0.00 : (parseFloat(pedido.venda.valorTotal) + parseFloat(pedido.venda.frete == null || isNaN(pedido.venda.frete) ? 0 : pedido.venda.frete)).toFixed(2) }}
            <small class="text-secondary"> ({{ pedido.venda.formaPgto }})</small>
         </div>
         <div class="card-descricao limitador">
            <span class="text-secondary" title="Última movimentação">
               <i class="far fa-calendar-exclamation font-10 color-theme me-1"></i> {{ pedido.venda.dataUltMov.dayOfMonth +'/'+ pedido.venda.dataUltMov.monthValue +'/'+ pedido.venda.dataUltMov.year +' '+ pedido.venda.dataUltMov.hour +':'+ pedido.venda.dataUltMov.minute }}
            </span>
         </div>
         <div class="card-descricao limitador">
            <span class="text-secondary" title="Cliente">
               <i class="far fa-user font-10 color-theme me-1"></i> {{ pedido.venda.nomeUsuario == null ? 'Desconhecido' : pedido.venda.nomeUsuario.toLowerCase() }}
            </span>
         </div>
      </div>
      <div class="text-center">
         <span class="badge badge2 bg-outline-theme cursor-pointer my-2 me-1" @click="visualizar"> 
            <i class="far fa-eye font-10 me-1"></i> Visualizar
         </span>
         <span class="badge badge2 bg-outline-theme cursor-pointer my-2 ms-1" @click="editarStatus"> 
            <i class="far fa-pen font-10 me-1"></i> Status
         </span>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Pedido',
   props: ['pedido', 'index', 'view'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      visualizar : function () {
         this.$emit('visualizar', this.pedido)
      },
      editarStatus : function () {
         let ref = this;

         Swal.fire({
            icon: 'info',
            title: 'Alterar status',
            input: 'select',
            inputOptions: {
               'Pendente': 'Pendente',
               'Em separação': 'Em separação',
               'Cancelado': 'Cancelado',
               'Entrega em andamento': 'Entrega em andamento',
               'Finalizado': 'Finalizado'
            },
            inputValue: ref.pedido.venda.status,
            inputPlaceholder: 'Selecione um status',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!value) {
                  return 'Opção inválida!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'adminproduct/changestatus',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.pedido.venda.id,
                     status: result.value
                  }
               }).then(function () {
                  ref.pedido.venda.status = result.value

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Status salvo!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
                  ref.$store.dispatch('buscarPedidosPendentes')
               })
            }
         });
      }
   }
}

</script>